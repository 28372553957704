<template>
    <v-container class="pa-0 pa-sm-3">
        <v-row v-if="copiedOrder != null" no-gutters>
            <v-col cols="12" sm="6">
                <v-card flat outlined>
                    <v-toolbar class="primary" dark dense>
                        <v-btn v-if="currentCategory == null" large @click="$emit('back')" icon>
                            <v-icon large>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-btn v-else large @click="currentCategory = null" icon>
                            <v-icon large>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{( currentCategory != null ? currentCategory.categoryName : 'Products' )}}</v-toolbar-title>
                        <v-spacer />
                        <v-btn class="d-sm-none" :disabled="copiedOrder.orderItems.some(x => x.quantity > 0)" icon>
                            <v-icon>mdi-cart</v-icon>
                        </v-btn>
                        <!-- <v-btn class="primary lighten-1 d-none d-sm-inline" :disabled="copiedOrder == null || !copiedOrder.orderItems.some(x => x.quantity > 0)" text @click="$emit('next')">Next</v-btn> -->
                    </v-toolbar>
                    <v-slide-y-transition hide-on-leave group>
                        <v-card v-if="mUseCategories && currentCategory == null" class="overflow-y-auto" key="1" style="height: calc(100vh - 96px)">
                            <v-list dense>
                                <v-card v-for="(category, ind) in categories" :key="ind" @click="selectCategory(category)" class="ma-1">
                                    <v-list-item dense three-line>
                                        <v-list-item-avatar rounded>
                                            <v-img :src="productLogoURL(category.id)" class="my-auto">
                                                <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-shape-outline</v-icon></template>
                                            </v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ category.categoryName }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ category.categoryDescription }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-list>
                        </v-card>
                        <v-card v-else-if="isLengthyArray(filteredOrderItems)" class="overflow-y-auto" key="2" style="height: calc(100vh - 96px)">
                            <v-list dense >
                                <template v-for="(orderItem, index) in filteredOrderItems">
                                    <Order-Line-Item 
                                        canEdit
                                        :item="orderItem"
                                        :showImages="showImages"
                                        :key="index"
                                        @change="updateOrder" />
                                    <v-divider :key="'d' + orderItem.productID" />
                                </template>
                            </v-list>
                        </v-card>
                        
                        <v-container v-else key="3">
                            Nothing Available To This Customer
                        </v-container>
                    </v-slide-y-transition>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card flat outlined>
                    <v-toolbar class="primary" dark dense>
                        <v-toolbar-title>Cart</v-toolbar-title>
                    </v-toolbar>
                    <v-list class="overflow-y-auto" dense key="2" style="height: 100%">
                        <template v-for="(orderItem, index) in copiedOrder.orderItems">
                            <Order-Line-Item 
                                canEdit
                                :item="orderItem"
                                :showImages="showImages"
                                :key="index"
                                @change="updateOrder" />
                            <v-divider :key="'d' + orderItem.productID" />
                        </template>
                    </v-list>
                    <v-row no-gutters key="4">
                        <v-col class="text-right" cols="12">
                            <p class="text-right ma-3">Subtotal: {{ copiedOrder.subTotal | toCurrency }}</p>
                            <p class="text-right mx-3 mb-1">GST: {{ copiedOrder.taxTotal | toCurrency }}</p>
                        </v-col>
                        <v-col cols="12">
                            <v-divider class="my-1" />
                        </v-col>
                        <v-col cols="12">
                            <h3 class="text-right mx-3 mt-2">Total: {{ copiedOrder.amountTotal | toCurrency }}</h3>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <!-- <v-card flat no-gutters>
        
        <v-fab-transition>
            <v-btn
                v-if="copiedOrder != null && copiedOrder.orderItems.some(x => x.quantity > 0)"
                @click="$emit('next')"
                bottom
                class="primary"
                fab
                fixed
                large
                left
                small
                style="margin-bottom: 50px;">
                <v-icon>mdi-check</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-card> -->
</template>

<script>
import { calculateAutoItems, loadOrderItems, updateOrder } from '~tools/helpers.js';

export default {
    name: 'BT-Order-Items',
    components: {
        OrderLineItem: () => import('~home/purchase-orders/Ordering-Line-Item.vue')
    },
    data: function() {
        return {
            allOrderItems: [],
            autoOrderItems: [],
            categories: [],
            copiedOrder: null,
            currentCategory: null,
            isLoaded: false,
            isSave: false,
            mUseCategories: false,
            oldAutoOrderItems: [] //for trying to retain ids and rowversions when editing (not creating)
        }
    },
    props: {
        height: null,
        order: null,
        policy: null,
        resetToggle: {
            type: Boolean,
            default: false
        },
        showImages: {
            type: Boolean,
            default: false
        },
        useCategories: {
            type: Boolean,
            default: false
        }
    },
    async mounted() {
        this.mUseCategories = this.useCategories;

        if (!this.isLoaded) {
            await this.getOrderItems();
        }
    },
    watch: {
        mDrawer(val) {
            if (val) {
                if (!this.isLoaded) {
                    this.getOrderItems();
                }
            }
            else {
                this.save();
            }
        },
        async resetToggle() {
            console.log('blahing');
            await this.getOrderItems();
        }
    },
    computed: {
        filteredOrderItems() {
            if (!this.isLengthyArray(this.categories) || !this.mUseCategories) {
                return this.allOrderItems;
            }
            else if (this.currentCategory != null) {
                if (this.currentCategory.id == 'other') {
                    return this.allOrderItems.filter(z => z.data.product == null || z.data.product.categoryID == null);
                }
                else {
                    return this.allOrderItems.filter(z => z.data.product != null && z.data.product.categoryID == this.currentCategory.id);
                }
            }

            return [];
        }
    },
    methods: {
        async getOrderItems() {
            if (this.policy == null || this.order == null) {
                return;
            }

            this.currentCategory = null;

            this.copiedOrder = this.order; //this.copyDeep(this.order);

            this.oldAutoOrderItems = this.copiedOrder.orderItems.filter(x => x.productID == null);

            //remove auto items in copied order
            this.copiedOrder.orderItems = this.copiedOrder.orderItems.filter(x => x.productID != null);

            //sort?
            this.allOrderItems = loadOrderItems(this.copiedOrder, this.policy);

            this.autoOrderItems = calculateAutoItems(this.policy, this.allOrderItems, this.copiedOrder);

            this.updateOrder();

            if (this.mUseCategories) {
                var categoryIDs = [...new Set(this.policy.orderItems.filter(z => z.product != null && z.product.categoryID != null).map(x => x.product.categoryID))];
                if (this.isLengthyArray(categoryIDs)) {
                    this.categories = await this.$BlitzIt.store.getAll('public-product-categories', { ids: categoryIDs.toString() });

                    if (this.isLengthyArray(this.categories)) {
                        if (this.policy.orderItems.some(z => z.product == null || z.product.categoryID == null)) {
                            this.categories.push({
                                id: 'other',
                                categoryName: 'Other'
                            });
                        }
                    }
                }
                else {
                    this.mUseCategories = false;
                }
            }

            this.isLoaded = true;

            return this.allOrderItems;
        },
        selectCategory(category) {
            this.currentCategory = category;
        },
        updateOrder() {
            this.autoOrderItems = calculateAutoItems(this.policy, this.allOrderItems, this.copiedOrder);
            
            updateOrder(
                this.copiedOrder,
                this.policy,
                this.allOrderItems,
                this.autoOrderItems);

            this.$forceUpdate();
        }
    }
}
</script>